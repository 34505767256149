import { GetServerSideProps } from "next";

import Explore from "components/Explore";
import { Item } from "components/ItemDetail/interface";
import Layout from "components/Layout";
import api from "services/api";

export default function Home({ data, item }: any) {
  return (
    <Layout>
      <Explore
        categoriesData={data.categories}
        featuredCategoriesData={data.featuredCategories}
        sectionsData={data.items_section}
        options={data.options}
        favoriteMerchants={data.merchants}
        merchantData={data.merchantsDetails}
        item={item}
      />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const itemId = (context.query?.item as string) || null;
  let merchantOptions: never[] = [];

  const getItem = async (): Promise<Item | null> => {
    if (!itemId) return null;

    const { data } = await api.get<Item>(`/items/${itemId}`);
    const { data: merchantInfo } = await api.get(`/merchants/${data.merchant}`);
    const { data: merchant } = await api.get(
      `/merchants/${data.merchant}/menu`
    );

    merchantOptions = merchant.options;
    const metaPicture = data.featuredPicture.includes("firebasestorage")
      ? data.featuredPicture
      : `/_next/image?url=${data.featuredPicture}&w=640&q=50`;
    return {
      ...data,
      merchant: { ...merchant, ...merchantInfo },
      metaPicture,
    };
  };

  let { data } = await api.get("/explore");
  const item = await getItem();

  data.options = [...merchantOptions, ...data.options];

  return { props: { data, item } };
};
